/********** Add Your Global CSS Here **********/

.print .ant-modal-body {
  background: #f1f1f1;
}
.prevBox h3 {
  margin-bottom: 10px;
}
.prevBox h4 {
  margin-bottom: 5px;
  color: #a0a0a0;
  font-size: 13px;
  font-weight: 400;
}
.print .prnt_prv {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 1px;
  flex-wrap: wrap;
  /* transform: scale(0.8); */
}

.err {
  color: red;
}
.print .prevBox {
  width: 50%;
  /* flex: 1; */
  padding: 1px;
}

.cstm_lable .ant-form-item-label {
  padding-left: 50px !important;
}

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir="rtl"] .fomaCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.bread {
  padding: 20px;
}
.bread h4 {
  font-size: 30px;
  color: #000;
  text-transform: capitalize;
}
.bread span {
  font-size: 16px;
  color: #868686;
  font-weight: 600;
  text-transform: capitalize;
}

.flex1 {
  flex: 1;
}
.heading_box {
  margin: 0;
  padding: 0;
}
.space {
  width: 100%;
  height: 20px;
}
.fomoBoxWrapper td,
.ant-modal-body td,
th {
  border: 1px solid #ddd;
  padding: 10px !important;
}
.fomoBoxWrapper table th,
.ant-modal-body table th {
  background: #ddd;
  border-top: none;
  padding: 10px !important;
}
.fomoBoxWrapper td:first-child,
th:first-child,
.ant-modal-body th:first-child,
.ant-modal-body td:first-child {
  border-left: none;
}
.fomoBoxWrapper td:last-child,
th:last-child,
.ant-modal-body td:last-child,
.ant-modal-body th:last-child {
  border-right: none;
}

.ant-table-body {
  border-radius: 10px !important;
  overflow: hidden;
}
.ant-table-body {
  border: 1px solid #ddd !important;
}
/* .piechart{margin-top:40px;} */

.chartjs-render-monitor {
  margin-top: 50px;
}
/* .ant-table-pagination.ant-pagination{    display: none;} */

.fomaUser span {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
.fomaUser h4 {
  color: #5b5b5b;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

.ant-table-column-sorters {
  width: 100%;
  display: flex !important;
  align-items: center;
}
.ant-table-header-column {
  width: 100%;
}
.ant-table-column-title {
  flex: 1;
}

.panelDefult {
  padding: 0 20px;
}

.button_pannel {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 15px;
}
.button_pannel input {
  max-width: 40%;
  padding: 5px;
  height: auto;
}
.button_pannel button {
  margin-right: 10px;
  height: auto;
  padding: 5px 10px;
}
.button_pannel button i {
  font-size: 17px;
  margin-right: 10px;
}
.ion-ios-trash {
  color: red;
  margin-left: 10px;
}
.fz19 {
  font-size: 19px;
}

#chart3 .chart-legend g:first-child {
  display: none;
}
#chart3 .chart-legend g:nth-child(2) {
  transform: translateX(0);
  font-weight: 600;
}

.right_btn {
  text-align: right;
  width: 100%;
}
.right_btn button {
  margin-left: 10px;
}
.searchBox {
  display: block;
  width: 100%;
  text-align: right;
}
.searchBox input {
  width: 400px;
  text-align: left;
  margin-bottom: 10px;
  padding: 15px;
}




.printTopBottomMargin {
  margin-top: 15.15mm;
  margin-bottom: 15.15mm;
}

.prinLeftRightMargin {
  margin-left: 5.94mm;
  margin-right: 5.94mm;
}

.printBreak {
  height: 0.1mm;
  display: block;
  /* min-height: 30.30mm !important; */
}

.borderCell {
  min-height: 38.1mm;
  height: 38.1mm;
  width: 99.06mm;
  border: 1px dashed #ddd;
  padding: 10px !important;
}



.newPrint {
  display: none !important;
  border: 1px dashed #ddd;
  padding: 5px !important;
}
.newPrint:nth-child(1),
.newPrint:nth-child(2),
.newPrint:nth-child(3),
.newPrint:nth-child(4),
.newPrint:nth-child(5),
.newPrint:nth-child(6),
.newPrint:nth-child(7),
.newPrint:nth-child(8),
.newPrint:nth-child(9),
.newPrint:nth-child(10),
.newPrint:nth-child(11),
.newPrint:nth-child(12),
.newPrint:nth-child(13),
.newPrint:nth-child(14) {
  display: block !important;
}

.print-col {
  display: none !important;
  padding: 10px !important;
  border: 1px dashed #ddd;
}
.print-col:nth-child(1),
.print-col:nth-child(2),
.print-col:nth-child(3),
.print-col:nth-child(4),
.print-col:nth-child(5),
.print-col:nth-child(6),
.print-col:nth-child(7),
.print-col:nth-child(8),
.print-col:nth-child(9),
.print-col:nth-child(10),
.print-col:nth-child(11),
.print-col:nth-child(12),
.print-col:nth-child(13),
.print-col:nth-child(14) {
  display: block !important;
}

.prnt_prv .ant-row {
  display: flex;
  flex-wrap: wrap;
}
.newPrint {
  width: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media print {
  .newPrint {
    min-height: 38.1mm;
    min-width: 99.06mm;
    border: none !important;
    padding: 0 !important;
  }
  .border-col {
    min-height: 38.1mm;
    height: 38.1mm;
    width: 90.06mm;
    border: 1px dashed #ddd;
    padding: 10px !important;
  }

  .page-break {
    height: 38.1mm;
    display: block;
    min-height: 130px !important;
  }
  .newPrint {
    display: flex !important;
    width: 100% !important;
  }
  .print-col {
    display: flex !important;
    width: 100% !important;
  }
}
/*.member th.ant-table-selection-column .ant-checkbox-wrapper{position: relative;touch-action: manipulation;pointer-events: none;}*/
/*.member th.ant-table-selection-column .ant-checkbox-wrapper *{touch-action: manipulation;pointer-events: none;}*/

.flex1 {
  width: 100%;
}
.ant-table-body {
  overflow: auto;
}

@media (max-width: 680px) {
  .button_pannel {
    flex-wrap: wrap;
  }
  .button_pannel button {
    width: 46%;
    margin-bottom: 10px;
    min-width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .button_pannel input {
    width: 100%;
    max-width: 100%;
  }
}

.ant-modal-footer span:first-child {
  float: right;
  margin-left: 10px;
}

.ant-modal-footer {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.ant-modal-footer button {
  margin: 0 !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.ant-upload-list-item {
  width: 150px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: transparent !important;
}

.ant-upload-list-item .anticon-close {
  right: -10px !important;
  color: red !important;
}

@media (max-width: 680px) {
  .colInput .half {
    width: 40% !important;
  }
  .colInput .full {
    width: 55% !important;
  }
}

td a,
.foma-link {
  text-decoration: underline;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e1e1e1 !important;
}

.heading {
  padding: 20px 0;
}

/* .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
      min-width: 120px!important;
      max-width: 120px!important;    word-break: break-word;

} */

.fomaMenuHolder img {
  max-width: 14px;
  margin: 0 33px 0 0;
  opacity: 0.3;
}

.ant-menu-dark .ant-menu-item:hover img,
.ant-menu-dark .ant-menu-item-active img,
.ant-menu-dark .ant-menu-submenu-active img,
.ant-menu-dark .ant-menu-submenu-selected img,
.ant-menu-dark .ant-menu-submenu-title:hover img {
  opacity: 1;
}

.fomaLoginContent .fomaLogoWrapper a {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: #788195;
  text-align: center;
  text-transform: uppercase !important;
}

.loaderMain {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
